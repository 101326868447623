import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  padding: 2rem 4rem;
  max-width: 700px;
`;

const Box = ({ children }) => <Container>{children}</Container>;

Box.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Box;
