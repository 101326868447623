import React from 'react';

import Layout from 'components/common/Layout';
import Box from 'components/common/Box';

const NotFound = () => (
  <Layout>
    <Box>Not found.</Box>
  </Layout>
);

export default NotFound;
